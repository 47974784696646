import { Question } from "./question.model";
import { Testcase } from "./testCase.model";

export class QuestionOption {
  constructor(
    public id?: number,
    public content?: string,
    public explanation?: string,
    public correctOption?: boolean,
    public question?: Question,

    public className?: string,
    public methodName?: string,
    public testcases?: Testcase[]
  ){}
}
