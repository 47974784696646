import { Component, OnInit, Input } from '@angular/core';
import { SubjectRepository } from 'src/app/model/subject.repository';
import { Router, ActivatedRoute } from '@angular/router';
import { Question } from 'src/app/model/question.model';
import { Subject } from 'src/app/model/subject.model';
import { Article } from 'src/app/model/article.model';
import { UserRepository } from "src/app/model/user.repository";
import { OpenAiRepository } from '../../../model/openai.repository';
import * as _ from 'lodash';

import { UserSubject } from 'src/app/model/userSubject.model';
import { CompletedQuestion } from 'src/app/model/completedQuestion.model';
import { User } from 'src/app/model/user.model';
import { QuestionType } from "../../../service/constants";

import { MatDialog } from '@angular/material/dialog';
import { QuestionOption } from 'src/app/model/questionOption.model';
import { Behaviour } from 'src/app/model/Behaviour.model';

@Component({
  selector: 'app-weeklyquiz',
  templateUrl: './weeklyquiz.component.html',
  styleUrls: ['./weeklyquiz.component.css']
})
export class WeeklyquizComponent implements OnInit {
  questions: Question[] = [];
  subjects: Subject[] = [];
  question: Question;
  questionNum: number = 0;
  selectedOption="";
  articles: Article[] = [];

  public errorMessage: string;
  public successMessage: string;
  public explanation: string;
  public complete: boolean =false;
  public isTestCodeButtonDisabled: boolean = true;

  @Input() quizType: string;
  @Input() quizIndex: number=0;

  correctQuestions: CompletedQuestion[] = [];
  userSubject: UserSubject;
  user = new User();
  level = 0;
  public subCategory: string;
  public localQuestionType: any;

  public codeArea: string="";
  public showSpinner = false;

  constructor(private repository: SubjectRepository, private userRepository: UserRepository, private openAiRepository: OpenAiRepository, private router: Router, private activatedRoute: ActivatedRoute, public aiCodeAssistantDialog: MatDialog) {

  }

  ngOnInit(): void {
    this.level = this.activatedRoute.snapshot.params["level"];
    this.subCategory = this.activatedRoute.snapshot.params["subCategory"];
    this.localQuestionType = QuestionType;
    this.getWeeklyQuiz(0);
  }

  getWeeklyQuiz(index: number) {
    this.clearMessages();
    this.repository.getWeeklyQuiz().subscribe( data => {
      this.subjects = data;
      console.log("sub length"+this.subjects.length);
      this.questions = this.subjects[index].questions;
      this.assignQuestionQuizType();
    });
  }

  getNumExistingSubject():number {
    return this.subjects.length;
  }

  nextQuiz(index: number) {
    if (this.getNumExistingSubject() > index) {
      this.getWeeklyQuiz(index);
    } else {
      this.question = null;
    }

  }

  assignQuestionQuizType() {
    let found: boolean = false;
       this.questions.forEach(q => {
        if (q.questionType === this.quizType) {
         this.question = q;
         found = true;
         this.displayQuestionCodeInTextArea();
        }
      }
      )

      /**
       * Some subjects may only have coding or multiple choice question but not both.
       * To avoid scenario where clicking next button will show new multiple choice question but coding question is still previous one
       * and that can confuse user.
       */
      if (found === false) {
        this.question = null;
      }

  }

  checkAnswer() {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking checkAnswer button from Weekly Quiz page";
    this.userRepository.trackUserBehaviour(behaviour);

    this.errorMessage=null;
    this.explanation = null;
      if (_.isEmpty(this.selectedOption) ) {
        this.errorMessage="Please select one of the options."
      } else {
        this.errorMessage="Incorrect answer.";
        for (let i=0; i<this.question.options.length;i++){
          if(this.question.options[i].content === this.selectedOption && this.question.options[i].correctOption){
            this.errorMessage=null;
            this.explanation = this.question.options[i].explanation;
            break;
          }
        }
      }
  }

  compileCode() {
  //console.log("Compiling code");

  let behaviour: Behaviour = new Behaviour();
  behaviour.description = "User is clicking compileCode button from Weekly Quiz page";
  this.userRepository.trackUserBehaviour(behaviour);

  let codeSubmission: QuestionOption = new QuestionOption();
  //  codeSubmission = this.questions[this.questionNum]?.options[0];
  codeSubmission.className = this.question.options[0].className;
  codeSubmission.methodName = this.question.options[0].methodName;
  codeSubmission.testcases = this.question.options[0].testcases;
   codeSubmission.content=  `public class ${codeSubmission.className} { ${this.codeArea}}`;

    this.repository.compileAndRunCode(codeSubmission).subscribe(data => {
      this.clearMessages();
      this.successMessage = data.message;
    }, error=> {
      this.clearMessages();
      this.errorMessage = error.error.message;
    })
    this.isTestCodeButtonDisabled = false;
  }

  displaySelectedAnswAndExpla() {
    let hasFound = false;
    for (let i=0; i<this.correctQuestions.length;i++){
      if(this.questions[this.questionNum]?.id == this.correctQuestions[i]?.question.id){
        this.clearMessages();


          for (let j=0; j<this.questions[this.questionNum]?.options.length;j++){
            if(this.questions[this.questionNum]?.options[j].correctOption){

              this.explanation = this.questions[this.questionNum]?.options[j].explanation;
              this.selectedOption = this.questions[this.questionNum]?.options[j].content;
              hasFound = true;
              break;
            }
          }


        break;
      }
    };



  }


  navigateToMyAccount() {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking my-account button from Weekly Quiz page";
    this.userRepository.trackUserBehaviour(behaviour);

    this.router.navigate(['myaccount']);
  }


  /**
   * This method is used to display initial code in text area for question type is coding test.
   * if this.explanation is true mean, codeArea has been populated with submitted code in (!hasFound) section of displaySelectedAnswAndExpla()
   */
  displayQuestionCodeInTextArea() {
    if (this.question?.questionType == this.localQuestionType.Coding_Quiz) {
      if (  !this.explanation ) {
        this.codeArea = this.question.options[0].content;
      }
    }
  }

  clearMessages():void {
    this.errorMessage=null;
    this.explanation = null;
    this.successMessage = null;
   }

  getWindowInnerWidth():number {
    return window.innerWidth;
  }

  getWindowInnerHeight():number {
    return window.innerHeight;
  }

  getMainContainerHeight():number {
    return document.querySelector('#main-container').clientHeight;
  }

  getOptimalMainContainerHeight():string {
    if (this.getMainContainerHeight() >= this.getWindowInnerHeight()) {
      let pixel = this.getMainContainerHeight();
      return pixel+"px";
    } else {
      //footer is 200px and nav is around 50px
      let pixel = (this.getWindowInnerHeight() - (200+this.getMainContainerHeight())) +this.getMainContainerHeight();
      return this.getWindowInnerHeight()+200+"px";
    }
  }

}
