<!-- Footer -->

<footer class="customFooter">
  <div class="row">
    <div class="col-4 p-2">



    </div>
    <div class="col-4 p-2">
      <!-- Copyright -->
      <div>© 2024 Copyright: Tech District
      </div>
      <!-- Copyright -->

    </div>
    <div class="col-4 p-2"></div>
  </div>
  <div class="row">
    <div class="col-4 p-2">

      <a href="#" (click)="opensTermsDialog($event)">Privacy Policy</a><br>
      <a href="#" (click)="opensTermsDialog($event)">Terms and Conditions</a>

    </div>
    <div class="col-4 p-2">

      <a style="cursor: pointer;" (click)="navigateToAboutUs()">About Us</a> <br/>
      <a style="cursor: pointer;" (click)="navigateToCredit()">Credits</a>
    </div>
    <div class="col-4 p-2"><a style="cursor: pointer;" (click)="navigateToLecturerLogin()">Lecturer Login</a></div>
  </div>


</footer>

<!-- Footer -->
