import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UserRepository} from "../model/user.repository";
import {User} from "../model/user.model";
import { PrivacySetting } from '../model/privacy-setting.enum';
import { PrivacySettingKey } from '../model/privacy-setting-key.enum';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {

  user: User = new User();
  profileVisibility: boolean = true;

  constructor(private repository: UserRepository,private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.repository.getUserProfile(this.activatedRoute.snapshot.params["uname"]).subscribe(user => {
      this.user = user;

      if(this.user.privacySettings && this.user.privacySettings.length > 0) {
        for ( const setting of this.user.privacySettings) {
           if (setting.settingKey ===  PrivacySettingKey.PROFILE_VISIBILITY
               && setting.settingValue === PrivacySetting.PRIVATE) {
              this.profileVisibility = false;
           }
        }
      }
    });
  }

}
