<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand">Tech District</a>
    <button class="navbar-toggler" type="button" (click)="toogleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{'show': navbarOpen}">
      <ul class="navbar-nav">
        <li class="nav-item">
          <!-- <a class="nav-link active" style="cursor: pointer;" aria-current="page" (click)="navigateToHome()">Home</a> -->
          <a class="nav-link" style="cursor: pointer;" aria-current="page" (click)="navigateToHome()">Home</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Learning Resources</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="navigateToArticle(LocalSubCategory.HTML_CSS)"> HTML</a>
            <a class="dropdown-item" (click)="navigateToArticle(LocalSubCategory.JavaScript)"> JavaScript</a>
            <a class="dropdown-item" (click)="navigateToArticle(LocalSubCategory.Java)"> Java</a>
            <a class="dropdown-item" (click)="navigateToArticle(LocalSubCategory.Machine_Learning)"> Machine
              Learning</a>

          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" style="cursor: pointer;" aria-current="page" (click)="navigateToCaseStudy()">Case
            Studies</a>
        </li>

        <li class="nav-item">
          <a style="cursor: pointer;" class="nav-link" (click)=" navigateToSubscribe()">Subscribe</a>
        </li>



      </ul>


      <div class="d-flex">
        <input #searchValue class="form-control me-2" type="search" data-toggle="tooltip"
          title="Enter Article title or Article type here" placeholder="Search Articles here" aria-label="Search">
        <button class="btn btn-outline-success" (click)="search(searchValue.value)">Search</button>
      </div>





      <div class="smallScreenOnly">
        <div class="collapse navbar-collapse" [ngClass]="{'show': navbarOpen}">

          <ul class="navbar-nav">
            <li class="nav-item">
              <a style="cursor: pointer;" class="nav-link" (click)="navigateToMyAccount()"><i class="far fa-user"></i> MyAccount</a>
            </li>
            <li *ngIf="!isUserEmailExist()" class="nav-item">
              <button class="btn btn-success" (click)="navigateToRegistration()">Sign Up</button>
            </li>
            <br />
            <li *ngIf="isUserEmailExist()" class="nav-item">
              <button style="margin-top: 2px;" class="btn btn-success" (click)="logout()">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="largeScreenOnly">
      <div class="collapse navbar-collapse" [ngClass]="{'show': navbarOpen}">

        <ul class="navbar-nav">
          <li class="nav-item">
            <a  style="cursor: pointer;" class="nav-link" (click)="navigateToMyAccount()"><i class="far fa-user"></i> MyAccount</a>
          </li>
          <li *ngIf="!isUserEmailExist()" class="nav-item">
            <button class="btn btn-success btn-block" (click)="navigateToRegistration()"> Sign Up</button>
          </li>
          <li>
            <div *ngIf="isUserEmailExist()" class="button-container">
              <button class="btn btn-success btn-block centered-button" (click)="logout()">Logout</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

  <nav class="secondaryNavbar">
    <ul>
      <li>
        <img style="height: 60px;" src="../../assets/Learn.jpg"/>
        &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;
        <a href="https://startupaotearoa.org/" target="_blank"><img class="round-image" src="../../assets/startup_aotearoa.JPG" /></a>
      </li>

      <li>

      </li>

      <li>  </li>
      <li>

      </li>
    </ul>

    <ul>
      <li *ngIf="isUserEmailExist()">
        <p style="color:white">Hi &nbsp;{{getUserDetail().username}} </p>
      </li>
      <li *ngIf="!isUserEmailExist()">
        <p style="color:white"> </p>
      </li>
      <li><a style="cursor: pointer;" (click)="navigateToLearningPath()">Learning Path</a></li>
      <li> &nbsp; | &nbsp;</li>
      <li><a style="cursor: pointer;" (click)="navigateToFeedBack()">Feedback</a></li>
    </ul>

  </nav>


