<div class="container-fluid">

  <!--
  <div class="row" id="main-container" [style.height]="getOptimalMainContainerHeight()">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-2">

    </div>-->

 <!-- This is for wider screen like laptop phone-->
  <div  class="row" id="main-container">

    <div class="col-sm-12 col-md-12">


      <div class="row">
        <div class="col-sm-5">
          <h2>Multiple-Choice</h2>
          <app-weeklyquiz [quizType]="localQuestionType.Multiple_Choice_Quiz"></app-weeklyquiz>
        </div>
        <div class="col-sm-7">
          <h2>Coding</h2>
          <app-weeklyquiz [quizType]="localQuestionType.Coding_Quiz"  ></app-weeklyquiz>
        </div>

      </div>
      <br/>
      <div class="row fullWidth">
        <div class="col-sm-5"></div>
        <div class="col-sm-5">
          <button class="btn btn-primary m-1" *ngIf="isCompleted===false" (click)="next()">Next Question</button>
          &nbsp; &nbsp;
          <button class="btn btn-primary m-1" (click)="restart()">Restart</button>
        </div>
        <div class="col-sm-2"></div>
      </div>

    </div>

  </div>
</div>
