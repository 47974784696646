import { Component, OnInit } from '@angular/core';
import { TermsDialogComponent } from '../../service/terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Behaviour } from 'src/app/model/Behaviour.model';
import { UserRepository } from '../../model/user.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private dialog: MatDialog, private userRepository: UserRepository, private router: Router) { }

  ngOnInit(): void {
  }

  opensTermsDialog(event: Event): void {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking T-C button from Footer Navigation Bar";
    this.userRepository.trackUserBehaviour(behaviour);

    event.preventDefault();
    const dialogConfig = {
      data: {
        isAcceptDecline: false
      }
    };

    this.dialog.open(TermsDialogComponent, dialogConfig);
  }

  navigateToAboutUs() {

      let behaviour: Behaviour = new Behaviour();
      behaviour.description = "User is clicking aboutUs button from Footer Navigation Bar";
      this.userRepository.trackUserBehaviour(behaviour);

      this.router.navigate(['aboutUs']);
    }

    navigateToCredit() {

        let behaviour: Behaviour = new Behaviour();
        behaviour.description = "User is clicking credit button from Footer Navigation Bar";
        this.userRepository.trackUserBehaviour(behaviour);

        this.router.navigate(['credit']);
      }

      navigateToLecturerLogin() {

          let behaviour: Behaviour = new Behaviour();
          behaviour.description = "User is clicking Lecturer Login button from Footer Navigation Bar";
          this.userRepository.trackUserBehaviour(behaviour);

          this.router.navigate(['admin']);
        }

}
