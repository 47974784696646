import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {ModelModule} from "../model/model.module";
import {StoreComponent} from "./store.component";
import {AboutUsComponent} from "./aboutUs/aboutUs.component";
import {CounterDirective} from "./counter.directive";
import { RouterModule} from "@angular/router";
import {MatCardModule} from '@angular/material/card';
import { NavBarModule } from "../nav-bar/nav-bar.module";

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatExpansionModule} from "@angular/material/expansion";

import { FooterComponent } from '../nav-bar/footer/footer.component';
import { PredictionComponent } from '../prediction/prediction.component';
import { ProductDetailComponent } from './product/productDetail.component';

import { GalleryComponent } from "../imageGallery/gallery.component";
import { GalleryDirective } from "../imageGallery/gallery.directive";

import { DialogComponent } from "./dialog/dialog.component";
import { ServiceModule } from "../service/service.module";

import { EmailConfirmationComponent } from "./emai/emailConfirmation.component";
import { SubscriptionComponent } from './subscription/subscription/subscription.component';
import { LearningPathComponent } from './learning-path/learning-path.component';
import { CreditComponent } from './credit/credit.component';
import { WeeklyquizComponent } from "./weekly-quiz-wrapper/weekly-quiz/weeklyquiz.component";
import { WeeklyQuizWrapperComponent } from './weekly-quiz-wrapper/weekly-quiz-wrapper.component';

@NgModule({
  imports: [ModelModule, BrowserModule, FormsModule, RouterModule, NavBarModule, ServiceModule, MatCardModule,
    MatProgressSpinnerModule, MatExpansionModule
  ],
  providers: [
    ],
  declarations: [StoreComponent, CounterDirective,
  PredictionComponent, ProductDetailComponent, GalleryComponent, GalleryDirective, AboutUsComponent, DialogComponent, EmailConfirmationComponent, SubscriptionComponent, LearningPathComponent, CreditComponent, WeeklyquizComponent, WeeklyQuizWrapperComponent ],
  exports: [StoreComponent, ProductDetailComponent, EmailConfirmationComponent]
})
export class StoreModule {}
