
<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>


  <div class="row main-container">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">

      <div class="profile-container">
        <div *ngIf="profileVisibility">
            <div class="profile-header">
              <!-- <img
                *ngIf="user.profilePicture"
                [src]="user.profilePicture"
                alt="Profile Picture"
                class="profile-picture"
              /> -->
              <h1>{{ user.firstname }} {{user.lastname}}</h1>
            </div>

            <div class="profile-details">

                <h2 style="text-decoration: underline;">Contact Details</h2>
                <p *ngIf="user.email">Email: {{ user.email }}</p>
                <p *ngIf="user.phone">Phone: {{ user.phone }}</p>


              <div *ngIf="user.userSubjects && user.userSubjects.length > 0 ">
                <h2 style="text-decoration: underline;">Exercise Completion Badges</h2>
                <div class="badges-container">
                  <div class="badge" *ngFor="let userSub of  user.userSubjects">
                    <div class="badgeimg">
                      <img src="../../assets/badge.jpg" />
                      <!-- Tooltip Icon -->
                      <span
                      class="info-icon"
                      matTooltip="{{ userSub.subject.description }}"
                      matTooltipPosition="above"
                    >
                      ?
                    </span>
                    </div>

                    <span>{{userSub.subject.title }}</span>
                    <span>Completed Date: {{userSub.completedDate | date:'yyyy-MM-dd'}}</span>

                  </div>
                </div>
              </div>
            </div>
       </div>
       <div *ngIf="!profileVisibility">
        <h2>Sorry this user profile is private.</h2>
       </div>
      </div>

    </div>
    <div class="col-sm-2"></div>
  </div>








  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>
